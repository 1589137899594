<template>
  <b-navbar
    class="navbar navigation-wrapper scrolling-navbar"
    toggleable="lg"
    :fixed="'top'">
    <b-container class="content-container">
      <!-- DOWNLOAD BUTTONS -->
      <div class="download-container">
        <img
          class="app-store"
          src="../../assets/images/Apple-IOS-Store-Download.png"
          alt="Apple IOS Store Download"
          title="Download Healthy Hip Hop App for iOS"
          @click="redirect(appStoreLink)" />
        <br>
        <img
          class="play-store"
          src="../../assets/images/Google-Play-Button.png"
          alt="Google Play Button"
          title="Download Healthy Hip Hop App for Android"
          @click="redirect(playStoreLink)" />
      </div>

      <!-- LOGO -->
      <b-navbar-brand href="/">
        <img
          class="h3-logo img-fluid"
          src="../../assets/images/h3_logo.png"
          alt="H3, Healthy Hip Hop, Logo"
        />
      </b-navbar-brand>

      <!-- TOGGLE -->
      <b-navbar-toggle target="navbar-collapse"></b-navbar-toggle>

      <!-- NAVIGATION ITEMS -->
      <b-collapse id="navbar-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
           :class="{'active' : $route.path === '/'}"
            to="/">
            Home
          </b-nav-item>
          <b-nav-item
            :class="{'active' : $route.path === '/schools'}"
            to="/schools">
            School
          </b-nav-item>
          <b-nav-item
            :class="{'active' : $route.path === '/parents'}"
            to="/parents">
            Parents
          </b-nav-item>
          <b-nav-item
            :class="{'active' : $route.path === '/login'}"
            to="/login">
            Login
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
  export default {
    name : 'AppHeader',
    data() {
      return {
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
      }
    },
    methods : {

      /**
       * Redirect page to download H3 App
       * @param link
       */
      redirect(link) {
        if (link == this.appStoreLink) {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
        }
        window.open(link, '_target');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .navigation-wrapper {
    z-index: 11;
    background-color: rgba(255,255,255,0.8);
    border-bottom: 5px solid $blue;
    
    .download-container {
      display: inline-block;
      .app-store, .play-store {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .navbar-brand {
      text-align: center;
    }
    .nav-link {
      color: $blue;
      font-family: 'WickedMouse';
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .download-container {
      max-width: 20%;
      .app-store, .play-store {
        margin: 0.1rem 0;
        max-width: 100%;
      }
    }
    .navbar-brand {
      max-width: 50%;
      .h3-logo {
        max-width: 75%;
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .download-container {
      max-width: 20%;
      .app-store, .play-store {
        margin: 0.1rem 0;
        max-width: 90%;
      }
    }
    .navbar-brand {
      max-width: 50%;
      .h3-logo {
        max-width: 70%;
      }
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .download-container {
      max-width: 15%; //20%;
      .app-store, .play-store {
        margin: 0.1rem 0;
        max-width: 100%;
      }
    }
    .navbar-brand {
      margin-right: 0;
      text-align: center !important;
      .h3-logo {
        max-width: 60%;//75%;
      }
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .download-container {
      max-width: 15%;//20%;
      .app-store, .play-store {
        margin: 0.1rem 0;
        max-width: 75%;//80%;
      }
    }
    .navbar-brand {
      text-align: left !important;
      .h3-logo {
        max-width: 55%;//70%;
      }
    }
    .nav-link {
      margin-left: 2rem;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .download-container {
      max-width: 13%;//15%;
      .app-store, .play-store {
        margin: 0.2rem 0;
        max-width: 70%;//75%;
      }
    }
    .navbar-brand {
      text-align: left !important;
      .h3-logo {
        max-width: 55%;//70%;
      }
    }
    .nav-link {
      margin-left: 2rem;
    }
  }

  @media only screen and (min-width: $special) {
    .download-container {
      max-width: 11%;
      .app-store, .play-store {
        max-width: 85%;
      }
    }
    .navbar-brand {
      text-align: left;
      .h3-logo {
        max-width: 55%; //60%;
      }
    }
  }

  @media only screen and (min-width: $xl3-min) {
    .navbar-brand {
      .h3-logo {
        max-width: 75%;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    
  }
</style>